import React from "react";
import sectionIcon from "../images/section-icon.png";
// import helpingImage from "../images/helping-img.jpg";
const HelpingArea = () => {
  return (
    <div>
      <section className="mixer-area helping-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-heading mixer-heading">
                <div className="section-icon">
                  <img src={sectionIcon} alt="section-icon" />
                </div>
                <h2 className="section__title">
                  Give a helping hand for homeless childrens
                </h2>
              </div>
              <div className="helping-item">
                <div className="row">
                  <div className="col">
                    <div className="helping-box helping-box1">
                      <i className="icon-hamburger"></i>
                      <h4>food</h4>
                    </div>
                  </div>
                  <div className="col">
                    <div className="helping-box helping-box2">
                      <i className="icon-water-bottle"></i>
                      <h4>water</h4>
                    </div>
                  </div>
                  <div className="col">
                    <div className="helping-box helping-box3">
                      <i className="icon-health"></i>
                      <h4>Medical</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="helping-text-box">
                <p className="helping__text">
                  Homeless children face numerous challenges and hardships. By
                  giving a helping hand, we can make a difference in their
                  lives. Ways to help include volunteering at a local shelter or
                  soup kitchen, donating to charitable organizations that work
                  with homeless children, and educating yourself and others
                  about the issue of homelessness. By taking action, you can
                  help improve those lives.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HelpingArea;
