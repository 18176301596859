import React, { Component } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import sectionIcon from "../images/section-icon.png";

class MixerArea extends Component {
  constructor() {
    super();
    this.state = {
      startCounter: false,
    };
  }

  onVisibilityChange = isVisible => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <div>
        <section className="mixer-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="section-heading mixer-heading">
                  <div className="section-icon">
                    <img src={sectionIcon} alt="section-icon" />
                  </div>
                  <h2 className="section__title text__white">
                    Fundraising for the people and causes you care about
                  </h2>
                  <a href="/donate/" className="theme-btn">
                    start donation
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mixer-area2">
          <div className="container">
            <div className="row fun-content-wrap">
              <div className="col-lg-6">
                <div className="fun-content">
                  <div className="fun-item fun-item1">
                    <i className="icon-charity"></i>
                    <h3 className="counter">
                      <VisibilitySensor
                        onChange={this.onVisibilityChange}
                        offset={{ top: 10 }}
                        delayedCall
                      >
                        <CountUp end={this.state.startCounter ? 52300 : 0} />
                      </VisibilitySensor>
                    </h3>
                    <p className="fun__text">raised by 368 people in 7 days</p>
                  </div>
                </div>
                <div className="fun-content">
                  <div className="fun-item fun-item2">
                    <i className="icon-cancer"></i>
                    <h3 className="counter">
                      <VisibilitySensor
                        onChange={this.onVisibilityChange}
                        offset={{ top: 10 }}
                        delayedCall
                      >
                        <CountUp end={this.state.startCounter ? 630 : 0} />
                      </VisibilitySensor>
                    </h3>
                    <p className="fun__text">
                      volunteer are available to help you
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="fun-content-slide">
                  <Swiper>
                    <div className="fun-slide-item">
                      <div className="icon-quote">“</div>
                      <h3 className="funslide__text">
                        “To keep your mind and nature cleansed of impurities,
                        make a hut for your critics in your backyard and keep
                        them close.”
                      </h3>
                      <p className="funslide__name">Kabir</p>
                    </div>
                    <div className="fun-slide-item">
                      <div className="icon-quote">“</div>
                      <h3 className="funslide__text">
                        Don’t limit a child to your own learning, for he was
                        born in another time
                      </h3>
                      <p className="funslide__name">Rabindranath Tagore</p>
                    </div>
                    <div className="fun-slide-item">
                      <div className="icon-quote">“</div>
                      <h3 className="funslide__text">
                        Be so busy improving yourself that you have no time to
                        criticize others.
                      </h3>
                      <p className="funslide__name">Chetan Bhagat</p>
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default MixerArea;
