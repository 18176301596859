import React from "react";
import sectionIcon from "../images/section-icon.png";
import causeImage1 from "../images/img1.webp";
import causeImage2 from "../images/img2.webp";
import causeImage3 from "../images/img3.webp";
const CausesArea = () => {
  return (
    <div>
      <section className="causes-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="section-heading blog-heading text-center">
                <div className="section-icon">
                  <img src={sectionIcon} alt="section-icon" />
                </div>
                <h2 className="section__title">Latest Causes</h2>
                <p className="section__meta">help us now</p>
              </div>
            </div>
          </div>
          <div className="row blog-content-wrap">
            <div className="col-lg-4">
              <div className="blog-content">
                <div className="blog-item blog-item1">
                  <div className="blog-img">
                    <img src={causeImage1} alt="" />
                  </div>
                  <div id="bar1" className="barfiller">
                    <div className="tipWrap">
                      <span className="tip"></span>
                    </div>
                    <span className="fill" data-percentage="23"></span>
                  </div>
                  <div className="blog-inner-content">
                    <h3 className="blog__title">
                      <a href="#none">Blood Donation</a>
                    </h3>
                    <p className="blog__desc">
                      Donate now to help us save lives through organizing blood
                      donation camps.
                    </p>

                    <a href="/donate/" className="theme-btn">
                      donate now
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="blog-content">
                <div className="blog-item blog-item2">
                  <div className="blog-img">
                    <img src={causeImage2} alt="" />
                    <span className="blog__tag">
                      <i className="fa fa-flash"></i> trending
                    </span>
                  </div>
                  <div id="bar2" className="barfiller">
                    <div className="tipWrap">
                      <span className="tip"></span>
                    </div>
                    <span className="fill" data-percentage="80"></span>
                  </div>
                  <div className="blog-inner-content">
                    <h3 className="blog__title">
                      <a href="#none">Tree Plantation</a>
                    </h3>
                    <p className="blog__desc">
                      Help us plant a greener future by donating to our tree
                      plantation efforts.
                    </p>
                    <a href="/donate/" className="theme-btn">
                      donate now
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="blog-content">
                <div className="blog-item blog-item3">
                  <div className="blog-img">
                    <img src={causeImage3} alt="" />
                  </div>
                  <div id="bar3" className="barfiller">
                    <div className="tipWrap">
                      <span className="tip"></span>
                    </div>
                    <span className="fill" data-percentage="44"></span>
                  </div>
                  <div className="blog-inner-content">
                    <h3 className="blog__title">
                      <a href="#none">Sanitation</a>
                    </h3>
                    <p className="blog__desc">
                      Support our mission to improve sanitation and hygiene in
                      underprivileged communities.
                    </p>
                    <a href="/donate/" className="theme-btn">
                      donate now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CausesArea;
