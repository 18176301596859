import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import Footer from "../components/Footer";
import SliderOne from "../components/SliderOne";
import EntryArea from "../components/EntryArea";
import HiwArea from "../components/HiwArea";
import ServiceArea from "../components/ServiceArea";
import MixerArea from "../components/MixerArea";
import CausesArea from "../components/CausesArea";
import HelpingArea from "../components/HelpingArea";

const HomePage = () => {
    return (
        <Layout pageTitle="PDJJKS - Purba Dhansara Jote Jana Kalyan Samity[NGO]">
          <NavOne />
          <SliderOne />
          <EntryArea />
          <HiwArea />
          <ServiceArea />
          <MixerArea />
          <CausesArea />
          <div className="section-divider"></div>
          <HelpingArea />
          <Footer />
        </Layout>
    );
};

export default HomePage;
